<template>
  <div class="top-filter">
    <ul>
      <li
        v-for="(ele, index) in filters"
        :key="index"
        @click="filterEvent(ele.data)"
        :class="{ active: checkTopFilter(ele.data) }"
      >
        <span>
          <unicon :name="ele.icon"></unicon>
          {{ ele.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedFilter: "all",
      filters: [
        {
          label: "सबै सेवाहरु",
          icon: "favorite",
          data: "all"
        },
        {
          label: "महिला",
          icon: "venus",
          data: "women"
        },
        {
          label: "एकल महिला",
          icon: "user-exclamation",
          data: "single_women"
        },
        {
          label: "युवा",
          icon: "user",
          data: "youth"
        },
        {
          label: "शारीरिक असक्तता भएका व्यक्ति",
          icon: "wheelchair",
          data: "pwd"
        },
        {
          label: "ज्येष्ठ नागरिक",
          icon: "user-plus",
          data: "senior_citizen"
        },
        {
          label: "दलित",
          icon: "users-alt",
          data: "dalit"
        },
        {
          label: "जनजाती",
          icon: "users-alt",
          data: "janajati"
        },
        {
          label: "अन्य (WMG)",
          icon: "setting",
          data: "other_wmg"
        }
      ]
    };
  },
  methods: {
    checkTopFilter(data) {
      if (data === this.selectedFilter) return true;

      return false;
    },
    filterEvent(data) {
      this.selectedFilter = data;
      this.$emit("changeInTopFilter", data);
    }
  }
};
</script>

<style lang="scss">
.top-filter {
  background: $neutrals-midnight;
  box-shadow: 0px 25px 100px $neutrals-charcoal;
  padding: 0 100px;
  position: fixed;
  width: calc(100vw - 800px);
  z-index: 1;

  @media screen and (max-width: 3839px) {
    width: calc(100vw - 670px);
  }

  @media screen and (max-width: 3199px) {
    width: calc(100vw - 320px);
    padding: 0 28px;
  }

  @media screen and (max-width: 1800px) {
    width: calc(100vw - 285px);
  }

  ul {
    place-content: center space-between;
    display: flex;

    @media screen and (max-width: 3199px) {
      place-content: unset;
    }

    li {
      border-bottom: 8px solid transparent;
      padding: 68px 20px;
      cursor: pointer;

      @media screen and (max-width: 3199px) {
        padding: 17.5px 10px;
      }

      &:not(:last-child) {
        @media screen and (max-width: 3199px) {
          margin-right: 24px;
        }

        @media screen and (max-width: 1919px) {
          margin-right: 8px;
        }
      }

      &:hover {
        span {
          color: $secondary;
        }

        svg {
          fill: $secondary;
        }
      }
      &.active {
        border-color: $secondary;

        @media screen and (max-width: 3199px) {
          border-width: 2px;
        }

        span {
          color: $secondary;
        }

        svg {
          fill: $secondary;
        }
      }
      span {
        align-items: center;
        display: flex;
        font-size: 40px;

        @media screen and (max-width: 3199px) {
          font-size: 16px;
        }

        @media screen and (max-width: 1800px) {
          font-size: 14px;
        }
      }
    }
  }

  svg {
    fill: $neutrals-ash;
    width: 48px;
    height: 48px;
    margin-right: 20px;
    margin-top: -4px;

    @media screen and (max-width: 3199px) {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
  }
}
</style>
